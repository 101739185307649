@import '../../../../defaults';

.br-theme-fontawesome-stars .br-widget {
  display: flex;
  gap: 10px;
  white-space: nowrap;
}

.br-theme-fontawesome-stars .br-widget a {
  font: normal normal normal 20px/1 $anw-fontawesome-font-family;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  text-decoration: none;

  &:hover {
    background-color: transparent;
  }
}

.br-theme-fontawesome-stars .br-widget a:after {
  color: $yellow;
  font-weight: $anw-fontawesome-font-weight-regular;
  font-size: 47px;
  content: '\f005';
}

.br-theme-fontawesome-stars .br-widget a.br-active:after {
  font-weight: $anw-fontawesome-font-weight-solid;
  content: '\f005';
}

.br-theme-fontawesome-stars .br-widget a.br-selected:after {
  font-weight: $anw-fontawesome-font-weight-solid;
  content: '\f005';
}

.br-theme-fontawesome-stars .br-widget .br-current-rating {
  font-size: $anw-custom-h2-font-size;
  height: $anw-custom-h2-font-size;
  margin: 22px 0 4px;

  @media only screen and (max-width: $media-mobile-landscape-max) {
    font-size: $anw-custom-h4-font-size;
    height: $anw-custom-h4-font-size;
    margin: 10px 0 0;
  }
}

.br-theme-fontawesome-stars .br-readonly a {
  cursor: default;
}

.anw-content-bar-rating {
  .br-theme-fontawesome-stars .br-widget a {
    &::after {
      font-size: 32px;
    }
  }
}

.br-theme-fontawesome-stars + .form-errors,
.br-errors {
  li::before {
    display: none;
  }
}

@media print {
  .br-theme-fontawesome-stars .br-widget a:after {
    content: '\f006';
    color: black;
  }
  .br-theme-fontawesome-stars .br-widget a.br-active:after,
  .br-theme-fontawesome-stars .br-widget a.br-selected:after {
    content: '\f005';
    color: black;
  }
}

.ratingForm {
  .br-wrapper {
    margin-right: 45px;
  }
}
